import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ChatbotApp from "./ChatbotApp";
import Login from "./Login";
import AuthProvider from "./auth/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <AuthProvider>
            <Login />
          </AuthProvider>
        }
      />
    </Routes>
    <Routes>
      <Route
        path="/chatbot"
        element={
          <AuthProvider>
            <ChatbotApp />
          </AuthProvider>
        }
      />
    </Routes>
  </BrowserRouter>
);
