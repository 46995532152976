import React, { useState } from "react";
import Swal from "sweetalert2";

const SweetAlertHelper = () => {
  const [alertStatus, setAlertStatus] = useState(false);

  async function alertPopUp(alertMsg) {
    await Swal.fire({
      text: alertMsg,
      showCancelButton: false,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setAlertStatus(true);
      }
      if (result.isDismissed) {
        setAlertStatus(false);
      }
    });

    return alertStatus;
  }

  async function confirmPopUp(title, text) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          setAlertStatus(true);
          resolve(true);
        } else {
          setAlertStatus(false);
          resolve(false);
        }
      });
    });
  }

  async function confirmPopUpwithYesOrNo(title, text) {
    await Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setAlertStatus(true);
      }
      if (result.isDismissed) {
        setAlertStatus(false);
      }
    });

    return alertStatus;
  }

  return {
    alertPopUp,
    confirmPopUp,
    confirmPopUpwithYesOrNo,
  };
};

export default SweetAlertHelper;
