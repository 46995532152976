import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextField, FormControl, InputLabel } from "@mui/material";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { InputAdornment, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SweetAlertHelper from "./components/Alert/SweetAlertHelper";
import { Row, Col } from "react-bootstrap";
import "./Login.css";
import AuthContext from "./auth/AuthContext";
import axiosURL from "./components/Alert/axios";
import { useState } from "react";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setSignedInUser } = React.useContext(AuthContext);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const sweetAlertHelper = SweetAlertHelper();
  const [showMsg, setshowMsg] = useState(0);
  const [rememberMe, setRememberMe] = useState(false);
  const from = location.state?.from?.pathname || "/chatbot";
  const Email_REGEX =
    /^[\w!#$%&'*+/=?^`{|}~-]+(\.[\w!#$%&'*+/=?^`{|}~-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/;
  const Password_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  React.useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, from, navigate]);

  const handleClick = async (event) => {
    event.preventDefault();
    try {
      const obj = {
        email: email,
        password: password,
      };
      if (!Email_REGEX.test(obj.email)) {
        setEmailError("Enter valid Email");
        return;
      } else {
        setEmailError("");
      }
      const res = await axiosURL.post("/api/employee_details/login", obj);

      if (res.data.flag === 0) {
        setErrorMessage(false);
        setShowResetPassword(true);
      } else {
        setSignedInUser(res.data);
        const dd = res.data.employeeType.includes("Employee")
          ? "/chatbot"
          : "/chatbot";
        const from1 = location.state?.from?.pathname || dd;
        navigate(from1, { replace: true });
      }

      // }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        setErrorMessage(error.request);
      } else {
        setErrorMessage(error.message);
      }
    }
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const getCookie = (cname) => {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    const getCookieData = () => {
      console.log(document.cookie);
      var user = getCookie("myusername");
      var pswd = getCookie("mypassword");

      document.getElementById("Username").value = user;
      document.getElementById("Password").value = pswd;
      document.getElementById("rememberme").value = true;
      setEmail(user);
      setPassword(pswd);
      setRememberMe(true);
    };
    const isCookiePresent =
      getCookie("myusername") !== "" && getCookie("mypassword") !== "";

    if (isCookiePresent) {
      // Retrieve cookie data only if cookies are present
      getCookieData();
    }
  }, []);

  const inputType = showPassword ? "text" : "password";

  const setCookie = () => {
    const path =
      process.env.NODE_ENV === "production"
        ? "https://web-timesheet-frontend.azurewebsites.net/"
        : process.env.NODE_ENV === "uat"
        ? "https://uat-sst-timesheet.azurewebsites.net/"
        : "http://localhost:3000/";

    document.cookie = `myusername=${email}; path=${path}`;
    document.cookie = `mypassword=${password}; path=${path}`;
  };
  const deleteCookie = () => {
    const path =
      process.env.NODE_ENV === "production"
        ? "https://web-timesheet-frontend.azurewebsites.net/"
        : process.env.NODE_ENV === "uat"
        ? "https://uat-sst-timesheet.azurewebsites.net/"
        : "http://localhost:3000/";

    document.cookie = `myusername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
    document.cookie = `mypassword=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
  };
  useEffect(() => {
    if (rememberMe) {
      setCookie();
    } else {
      deleteCookie();
    }
  }, [email, password, rememberMe]);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleResetPassword = async (event) => {
    // Handle password reset logic here

    if (newPassword === confirmPassword) {
      const obj = {
        email: email,
        password: newPassword,
      };

      const res = await axiosURL.patch(
        "/api/employee_details/resetpassword",
        obj
      );
      setshowMsg(showMsg + 1);
      setPassword(newPassword);
      setShowResetPassword(false);
      // await sweetAlertHelper.alertPopUp("Password changed Successfully!");
    } else if (newPassword !== confirmPassword) {
      // Display an error message if the passwords do not match
      setPasswordsMatch(false);
      return;
    } else {
      await sweetAlertHelper.alertPopUp("Password wrong");
    }
  };

  const handleCloseResetPassword = () => {
    setNewPassword("");
    setConfirmPassword("");
    setPasswordsMatch(true);
    setShowResetPassword(false);
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (!Password_REGEX.test(e.target.value)) {
      setPasswordValidation(true);
      return;
    } else {
      setPasswordValidation(false);
      setNewPassword(e.target.value);
    }
  };
  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword1) => !prevShowPassword1);
  };
  const handleTogglePassword2 = () => {
    setShowPassword2((prevShowPassword2) => !prevShowPassword2);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <>
      <div
        className="login-container align-items-center"
        style={{ zIndex: 1 }}
        onKeyPre
        ss={handleKeyPress}
      >
        <div className="row" style={{ width: "980px" }}>
          <div className="col-md-1"></div>
          <div className="col-md-5 login-form-1">
            <img
              src={require("./assets/img/ChatGPT.jpg")}
              className="card-img-top"
              alt="loginimage"
              style={{ zIndex: -9999, position: "static" }}
            />
          </div>
          <div className="col-md-5 login-form-1 ju">
            <h3>
              <b>Login Here</b>
            </h3>
            <form id="myForm" onSubmit={handleClick}>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInputGroup">
                  Username
                </label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{
                        margin: "0px",
                        marginRight: "3px",
                        height: "35px",
                      }}
                    >
                      <i className="fa fa-user"></i>
                    </span>
                  </div>

                  <TextField
                    type="text"
                    id="Username"
                    className={`form-control ${
                      errorMessage.length > 0
                        ? errorMessage.includes("Email")
                          ? "is-invalid"
                          : ""
                        : ""
                    }`}
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                    style={{ height: "30px" }}
                  />
                </div>
                {emailError === "Enter valid Email" && (
                  <p style={{ color: "#FF4A55", fontSize: "16px" }}>
                    Enter valid Email Id
                  </p>
                )}
              </div>
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInputGroup">
                  Password
                </label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{
                        margin: "0px",
                        marginRight: "3px",
                        height: "35px",
                      }}
                    >
                      <i className="fa fa-lock"></i>
                    </span>
                  </div>
                  {/* <TextField
                    type={inputType}
                    id="Password"
                    className={`form-control ${
                      errorMessage.length > 0
                        ? errorMessage.includes("password")
                          ? "is-invalid"
                          : ""
                        : ""
                    }`}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                  />
                  <div
                    className="input-group-append"
                    style={{ margin: "0px 3px 0px 2px" }}
                  >
                    <span
                      className="input-group-text m-0"
                      onClick={handleTogglePassword}
                      style={{ height: "35px" }}
                    >
                      {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </span>
                  </div> */}
                  <TextField
                    type={inputType}
                    id="Password"
                    className={`form-control ${
                      errorMessage.length > 0
                        ? errorMessage.includes("password")
                          ? "is-invalid"
                          : ""
                        : ""
                    }`}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: "-17px" }}
                        >
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="remember">
                  <span>
                    <input
                      type="checkbox"
                      name="rememberme"
                      id="rememberme"
                      checked={rememberMe}
                      size="20px"
                      onClick={handleRememberMeChange}
                    />{" "}
                  </span>
                  <label className="ml-2" style={{ marginLeft: "5px" }}>
                    Remember Me
                  </label>
                </div>
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
              <div
                className="form-group"
                style={{ marginLeft: "110px", marginTop: "10px" }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  form="myForm"
                  style={{ backgroundColor: "#03294e" }}
                >
                  Submit
                </Button>
              </div>
              <div
                className="form-group"
                style={{ marginLeft: "70px", marginTop: "10px" }}
              >
                <a
                  href="#"
                  className="ForgotPwd"
                  style={{ color: "#03294e" }}
                  onClick={async () => {
                    await sweetAlertHelper.alertPopUp("OnForgot Password?");
                    setShowResetPassword(true);
                  }}
                >
                  <h5>
                    <b>Forgot Password?</b>
                  </h5>
                </a>
              </div>
            </form>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <div style={{ zIndex: 9999, position: "realtive" }}>
        <Dialog
          open={showResetPassword}
          onClose={handleCloseResetPassword}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 9999 }}
          PaperProps={{ style: { minWidth: "300px" } }}
        >
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent dividers>
            <Row>
              <Col md={12} className="mb-3">
                {" "}
                <FormControl variant="standard">
                  <label>Enter Password</label>
                  <TextField
                    placeholder="Enter Valid Password"
                    variant="outlined"
                    type={showPassword1 ? "text" : "password"}
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword1}>
                            {" "}
                            {showPassword1 ? <BsEyeFill /> : <BsEyeSlashFill />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                {passwordValidation && (
                  <p style={{ color: "red" }}>
                    Password must be 8-24 characters and contain at least one
                    uppercase letter, one lowercase letter, one number, and one
                    special character (!).
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>
                    <span aria-label="dollar-sign">$</span>{" "}
                    <span aria-label="percentage">%</span>
                  </p>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb-3">
                <FormControl variant="standard">
                  <label>Confirm Password</label>
                  <TextField
                    variant="outlined"
                    type={showPassword2 ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword2}>
                            {" "}
                            {showPassword2 ? <BsEyeFill /> : <BsEyeSlashFill />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      !passwordsMatch ? (
                        <p style={{ color: "red", fontSize: "14px" }}>
                          Password do not match
                        </p>
                      ) : undefined
                    }
                  />
                </FormControl>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => handleResetPassword(event)}
              style={{ backgroundColor: "#03294e", color: "white" }}
            >
              Reset
            </Button>

            <Button
              onClick={handleCloseResetPassword}
              style={{ backgroundColor: "#03294e", color: "white" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Login;
