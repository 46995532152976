import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import { Configuration, OpenAIApi } from "openai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AIChatBot.css";
import axios from "axios";
let demo = "";
let specialistName = "";
let sub_specialistName = "";
let subSpecialistNames = [];
let practice_name = "";
let physicianNames = [];
let physician_name = "";
const AHSChatBot = () => {
  const configuration = new Configuration({
    apiKey: "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",
  });

  const openai = new OpenAIApi(configuration);
  const [response, setResponse] = useState("Hi");
  const [assistant, SetAssistant] = useState("");
  const [messages, setMessages] = useState([]);
  const [textBeforeButtons, SettextBeforeButtons] = useState("");
  const [buttonNames, SetbuttonNames] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [SpecialistList, setSpecialistList] = useState(null);
  const [subSpecialistList, setSubSetSpecialistList] = useState(null);
  const [coeList, setcoeList] = useState(null);
  const [physicianList, setPhysicianList] = useState(null);
  const [dropdownNames, SetDropdownNames] = useState([]);
  const [dropdownClicked, setDropdownClicked] = useState(null);
  const [prevDropdownClicked, setPrevDropdownClicked] = useState(null);
  const [helpdocument, setHelpDocument] = useState("");
  const [isfaqbutton, Setisfaqbutton] = useState(false);
  const loggedInUser = sessionStorage.getItem("currentUser");
  const chatContainerRef = useRef();
  const lastMessageRef = useRef();

  let empName = "";
  let empType = "";
  try {
    const userObj = JSON.parse(loggedInUser);
    console.log("userObj: ", userObj);
    empName = userObj.full_name;
    empType = userObj.employeeType;
  } catch (error) {
    console.error("Error parsing user data:", error);
  }
  console.log("employee Number:", empName);

  const extractButtonNames = (text) => {
    const startIndex = text.indexOf("Buttons: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };

  // const extractDropdownopt = (text) => {
  //   const startIndex = text.indexOf("Dropdown: ['") + 11;
  //   const endIndex = text.indexOf("']", startIndex);
  //   if (startIndex !== -1 && endIndex !== -1) {
  //     const buttonString = text.substring(startIndex, endIndex);
  //     return buttonString.split("', '");
  //   }
  //   return [];
  // };

  const extractDropdownopt = (text) => {
    const startIndex = text.indexOf("Dropdown: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      // Remove single quotes from each option in the dropdown
      const options = buttonString
        .split("', '")
        .map((option) => option.replace(/'/g, ""));
      return options;
    }
    return [];
  };

  useEffect(() => {
    specialist_listapi();
  }, []);

  const specialist_listapi = async () => {
    try {
      const body = {
        loggedInUserId: "530",
      };
      const res = await axios.post(
        "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getSpecialties",
        body
      );
      console.log("res axios:", res);
      const { data } = res; // Use res.data directly
      console.log("Data received:", data);

      setSpecialistList(res.data.commandResult.data.specialities);

      const specialistData = JSON.stringify(
        res.data.commandResult.data.specialities
      );
      console.log("specialistData in try", specialistData);
      return specialistData;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  // useEffect(() => {
  //   subSpecialist_listapi();
  // }, []);

  const subSpecialist_listapi = async (speciality) => {
    try {
      const body = {
        speciality: speciality,
      };
      const res = await axios.post(
        "https://sls-eus-prod-elemrex-esign-05.azurewebsites.net/api/getSubSpecialties",
        body
      );

      console.log("res axios:", res.data.commandResult.data.sub_specialities);

      const { data } = res; // Use res.data directly
      console.log("Data received:", data);

      setSubSetSpecialistList(res.data.commandResult.data.sub_specialities);

      subSpecialistNames = res.data.commandResult.data.sub_specialities;
      console.log("subSpecialistNames in try", subSpecialistNames);
      // let nContext = [
      //   ...context,
      //   {
      //     role: "system",
      //     content: `here is subSpecialistNames  ${JSON.stringify(
      //       subSpecialistNames
      //     )}`,
      //   },
      // ];
      // setContext(nContext);

      return subSpecialistNames;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  // useEffect(() => {
  //   coe_listapi();
  // }, []);

  const coe_listapi = async (speciality, subSpecialty) => {
    try {
      const body = {
        speciality: speciality,
        subSpecialty: subSpecialty,
      };

      const res = await axios.post(
        "https://sls-eus-prod-elemrex-esign-05.azurewebsites.net/api/getCoeBySubSpeciality",
        body
      );

      console.log("res axios:", res);

      const { data } = res; // Use res.data directly

      console.log("Data received:", data);

      setcoeList(data);

      const coeList = JSON.stringify(data);
      console.log("coeList in try", coeList);

      return coeList;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  const physician_listapi = async (speciality, subSpeciality, companySlug) => {
    try {
      const body = {
        speciality: speciality,
        subSpeciality: subSpeciality,
        companySlug: "jamiewisser",
      };

      const res = await axios.post(
        "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getCoePhysicians",
        body
      );

      console.log("res axios:", res);

      const { data } = res; // Use res.data directly

      console.log("Data received:", data);

      setcoeList(data);

      const physicianList = JSON.stringify(data);
      console.log("physicianList in try", physicianList);

      return physicianList;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  const handleDropdownChange = async (event) => {
    setDropdownClicked(event.target.value);
    demo = event.target.value;
    let nContext;
    if (specialistName === "") {
      specialistName = event.target.value;
      // demo = specialistName;
      let subspelist = await subSpecialist_listapi(specialistName);
      console.log("subspelist", subspelist);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is subSpecialistNames  ${JSON.stringify(subspelist)}`,
        },
      ];
      // setContext(nContext);
    } else if (sub_specialistName === "") {
      sub_specialistName = event.target.value;
      console.log("In API.....");
      console.log(
        "specialistName-sub_specialistName",
        sub_specialistName,
        specialistName
      );

      // demo = specialistName;
      let coenamelist = await coe_listapi(specialistName, sub_specialistName);
      console.log("coenamelist", coenamelist);
      nContext = [
        ...context,

        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(coenamelist)}`,
        },
      ];
    } else if (practice_name === "") {
      practice_name = event.target.value;
      console.log("practice_name: ", practice_name);
      console.log("In API physician.....");
      console.log(
        "physian list",
        sub_specialistName,
        specialistName,
        practice_name
      );

      // demo = specialistName;
      let physicianlistname = await physician_listapi(
        specialistName,
        sub_specialistName
        //  practice_name
      );
      console.log("physicianlistname", physicianlistname);
      nContext = [
        ...context,

        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(physicianlistname)}`,
        },
      ];
    } else {
      nContext = [...context, { role: "user", content: event.target.value }];
    }
    setContext(nContext);
    const userMessage = {
      text: event.target.value,
      user: true,
    };
    const aiResponse = {
      text: "loading...",
      user: false,
    };
    setPrevDropdownClicked(specialistName);
    setMessages([...messages, userMessage, aiResponse]);
    getCompletionFromMessages(nContext);
    SetDropdownNames([]); // Clear dropdown options after selecting one
  };

  console.log(" specialistName clicked", specialistName, typeof specialistName);
  console.log(" Dropdown dropdown clicked", dropdownClicked);
  console.log("subSpecialistList in out of try", subSpecialistNames);
  console.log("buttonClicked: ", buttonClicked);
  //  ${JSON.stringify(SpecialistList)}

  // const final_answer =
  //   "The list of specialist are: (1,'Allergy and Immunology'),(2.'Anesthesiology'),(3.cardiology'),(4.'Cosmmatics')";

  const initialContext = [
    {
      role: "system",
      content: `
         You are a friendly Employee Assistance ChatBot, providing information about Expert opinion, Search a document, FAQ or Appointment process.
        first greet the user with their name ${empName} (note : take only firstname and greet) and ask like do you have any question about Expert opinion, Search a document, FAQ and Appointment. also provide Buttons array(note: Buttons B should be capital) Four button one for Expert opinion and one for Search a document and FAQ and Appointment like this, Buttons: [Expert opinion', ' Search a document', 'FAQ', 'Appointment'] \     
        `,
    },
  ];

  // Specialist Names: ${JSON.stringify(
  //   SpecialistList  // Specialist Names: ${SpecialistList}
  // )} (note: also provide Dropdown array(note: Dropdown D should be capital))

  //   const ExpertOpinionCOntext = `
  //   You are the expert opinion chatbot, here to assist with expert opinion related questions.
  //   When the user clicks on Expert opinion button then:
  //  You can ask following :
  //  - Select specialist name
  //   Specialist Names: ${JSON.stringify(
  //     SpecialistList
  //   )} note : create Dropdown array with speciality names from Specialist provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Multiple Sub-Specialty', 'sub-speciality2']
  //   - Select sub-specialist name
  //   Sub-Specialist Names: ${JSON.stringify(
  //     subSpecialistList
  //   )} note : create Dropdown array with sub-speciality names from Sub-Specialist provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Allergy and Immunology', 'speciality2']
  //   - Select Center Of Excellence(COE) name
  //   Center Of Excellence(COE) Names: ${JSON.stringify(
  //     coeList
  //   )} note : create Dropdown array with coe names from coe list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['coe1', 'coe2']
  //   - Select Physician name
  //  Physician Names: ${JSON.stringify(
  //    physicianList
  //  )} note : create Dropdown array with physician names from physician list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['phy1', 'phy2']
  //   Ask each question one by one  and store in respective json key.(note : tke practice name as ${practice_name})
  //   remember every user input and add to respective json key given in format below.\
  //   Json format should look like this and store in variable called "data" and ask please check all details and Do you want to as expert opinion or send email or book appointment? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Ask Expert Opinion', 'Send Email','Book Appointment]
  //     "data":  {
  //   "speciality": "speciality name",
  //   "subSpecialty": "subSpecialty name",
  //   "companySlug": "hospital name",
  //   "practiceName":"physician name"
  // }
  //     if the user asks other than expert opinion  question ex: timesheet, salary slip etc then say "I don't have this information. do you want to exit this conversation. also provide   Buttons array(note: Buttons B should be capital) two button one for Yes and one for No, Buttons: ['Yes', 'No']"\
  //     Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['Yes', 'No'] only shown this button only when you ask the question  Do you want to exit this conversation?
  //     At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;
  const FAQ_Document = ` You are the help document chatbot,here to assist with help document questions .
  ask the user Please ask the question
  if the user asks other than FAQ Document question ex: timesheet, salary slip etc then say "I don't have this information. do you want to exit this conversation. also provide   Buttons array(note: Buttons B should be capital) two button one for Yes and one for No, Buttons: ['Yes', 'No']"\
  Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['Yes', 'No'] only shown this button only when you ask the question  Do you want to exit this conversation?
  At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;
  const ExpertOpinionCOntext = `
    You are the expert opinion chatbot, here to assist with expert opinion related questions.
    When the user clicks on Expert opinion button then:
    You can ask following :
    create Dropdown array with speciality names from Specialist provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Multiple Sub-Specialty', 'sub-speciality2']
      - Select specialist name 
        Specialist Names: ${JSON.stringify(SpecialistList)} 
      - Select sub-specialist  
        Sub-Specialist Names: ${JSON.stringify(subSpecialistList)} 
      - Select Center Of Excellence(COE) name (note : consider subSpecialty and subSpeciality are same words) 
        Center Of Excellence(COE) Names: ${JSON.stringify(coeList)} 
      - Select Physician name (note : consider subSpecialty and subSpeciality are same words) 
        Physician Names: ${JSON.stringify(physicianList)}
    Store users each response one by one in respective json key.
    remember every user input and add to respective json key given in format below.
    Json format should look like this and store in variable called "data" and ask please check all details and Do you want to as expert opinion or send email or book appointment? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Ask Expert Opinion', 'Send Email', 'Book Appointment']
    "data":  
    {
      "speciality": "speciality name",
      "subSpecialty": "subSpecialty name",
      "companySlug": "hospital name",
      "physicianName":"physician name";
    }
    if user selects ask expert opinion button then 
    - ask to enter message and store user message response in previous json.
    - Show updated json data and ask user to upload your report 
    if the user asks other than expert opinion  question ex: timesheet, salary slip etc then say "I don't have this information. do you want to exit this conversation. also provide   Buttons array(note: Buttons B should be capital) two button one for Yes and one for No, Buttons: ['Yes', 'No']"\
    Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['Yes', 'No'] only shown this button only when you ask the question  Do you want to exit this conversation?
    At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;

  const newChat = `
  You are a friendly Employee Assistance ChatBot, providing information about Expert opinion, Search a document, FAQ or Appointment process.
  first greet the user with their name ${empName} (note : take only firstname and greet) and ask like do you have any question about Expert opinion, Search a document, FAQ and Appointment. also provide Buttons array(note: Buttons B should be capital) Four button one for Expert opinion and one for Search a document and FAQ and Appointment like this, Buttons: [Expert opinion', ' Search a document', 'FAQ', 'Appointment'] \     
   `;

  const [context, setContext] = useState(initialContext);

  const getCompletionFromMessages = async (ncontext) => {
    try {
      console.log("ncontext", ncontext);
      console.log("context in getcompletion....", context);
      const res = await openai.createChatCompletion({
        model: "gpt-4-0613",
        // model: "gpt-3.5-turbo",
        messages: ncontext,
        temperature: 0,
      });
      console.log(
        "getCompletionFromMessages",
        res.data.choices[0].message.content
      );

      let assres = res.data.choices[0].message.content;
      SetAssistant(assres);
      console.log("assres......", assres);
      if (assres.includes("Button")) {
        var buttonN = extractButtonNames(assres);
        SetbuttonNames(buttonN);
        // console.log("buttonN", buttonN);
      }

      if (assres.includes("Dropdown:")) {
        let dropdownopt = extractDropdownopt(assres);
        SetDropdownNames(dropdownopt);
        // console.log("dropdownopt", dropdownopt);
      }

      SettextBeforeButtons(assres.split("Buttons:")[0]);
      let textBeforeBut = "";
      if (assres.includes("Dropdown:")) {
        textBeforeBut = assres.split("Dropdown:")[0];
      } else {
        textBeforeBut = assres.split("Buttons:")[0];
      }
      console.log("response of user", demo);
      let userMessage;
      let newContext;
      if (response !== "") {
        userMessage = { text: response, user: true };
        newContext = [...ncontext, { role: "assistant", content: assres }];
      } else {
        userMessage = { text: demo, user: true };
        newContext = [
          ...ncontext,
          // { role: "user", content: demo },
          { role: "assistant", content: assres },
        ];
      }
      const aiResponse = { text: textBeforeBut, user: false };
      if (demo.includes("New")) {
        setMessages([userMessage, aiResponse]);
      } else {
        setMessages([...messages, userMessage, aiResponse]);
      }
      // setMessages([...messages, userMessage, aiResponse]);
      setContext(newContext);
      console.log("updated context", newContext);
      setResponse("");
      // SetbuttonNames([]);
    } catch (error) {
      console.error("Error fetching AI response", error);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the component is initially rendered
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    // Scroll to the last message when messages change
    if (messages.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    // ... (existing code)
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  const handleSendMessage = async () => {
    if (response.trim() !== "") {
      let prompt = response;
      console.log("prompt", prompt);
      let nContext;
      if (prompt.toLowerCase().includes("expert opinion")) {
        nContext = [
          { role: "system", content: ExpertOpinionCOntext },
          { role: "user", content: prompt },
        ];
        SetbuttonNames([]);
      } else if (prompt.toLowerCase().includes("faq")) {
        console.log("in FAQ");
        nContext = [
          { role: "system", content: FAQ_Document },
          { role: "user", content: prompt },
        ];
        Setisfaqbutton(true);
        SetbuttonNames([]);
      } else if (prompt.toLowerCase().includes("exit chat")) {
        nContext = [
          { role: "system", content: newChat },
          { role: "user", content: "New Chat" },
        ];
        SetbuttonNames([]);
        SetDropdownNames([]);
      } else {
        nContext = [...context, { role: "user", content: prompt }];
      }

      // const nContext = [...context, { role: "user", content: prompt }];
      setContext(nContext);
      const userMessage = { text: response, user: true };
      let aiResponse = { text: "loading...", user: false };
      // setMessages([...messages, userMessage, aiResponse]);
      setMessages((prevMessages) => [...prevMessages, userMessage, aiResponse]);

      console.log("response=>", response, isfaqbutton);
      if (isfaqbutton && !response.includes("thanks")) {
        console.log("faq api called");
        try {
          console.log("response in try", response);
          const body = {
            question: response,
            empType: empType,
          };
          const res = await axios.post(
            "https://openaitimesheet.azurewebsites.net/faq_search",
            body
          );
          console.log("res help_document :", res);
          const { data } = res.data.answer; // Use res.data directly
          let ans = res.data.answer;
          setHelpDocument(ans);
          aiResponse = { text: ans, user: false };
          // setMessages([...messages, userMessage, aiResponse]);
          setMessages((prevMessages) => {
            // Remove the last "loading..." message and add the new answer.
            const updatedMessages = prevMessages.slice(0, -1);
            return [...updatedMessages, aiResponse];
          });
        } catch (err) {
          console.error("Error:", err);
        }
      } else {
        // setResponse("");
        Setisfaqbutton(false);
        setLoadingResponse(true); // Show loading window
        try {
          await getCompletionFromMessages(nContext); // Wait for completion
        } catch (error) {
          console.error("Error sending message", error);
        }
        console.log("handleSendMessage", assistant);
      }
      setResponse("");
    }
  };

  return (
    <div className="chat-container" ref={chatContainerRef}>
      <Col className="chat-col lg-10">
        <div className="message-container">
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                alignSelf: message.user ? "flex-end" : "flex-start",
                backgroundColor: message.user ? "#e1ffc7" : "#f0f0f0",
                borderRadius: "10px",
                padding: "8px 12px",
                marginBottom: "5px",
                maxWidth: "80%",
              }}
            >
              {message.user ? (
                message.text
              ) : (
                <>
                  {message.text instanceof Object ? (
                    message.text
                  ) : (
                    <>
                      {message.text.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          <div dangerouslySetInnerHTML={{ __html: line }} />
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
          {dropdownNames.length > 0 && (
            <div>
              <select onChange={handleDropdownChange}>
                <option value="">Select an option</option>
                {dropdownNames.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}

          {textBeforeButtons !== "" && (
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                {buttonNames.map((buttonName, index) => (
                  <button
                    className="send-button"
                    key={index}
                    onClick={() => {
                      demo = buttonName;
                      setButtonClicked(buttonName);
                      setResponse(buttonName);
                      let nContext;
                      if (buttonName === "Expert opinion") {
                        nContext = [
                          { role: "system", content: ExpertOpinionCOntext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName === "FAQ") {
                        console.log("in FAQ");
                        nContext = [
                          { role: "system", content: FAQ_Document },
                          { role: "user", content: buttonName },
                        ];
                        Setisfaqbutton(true);
                      } else if (buttonName.includes("New")) {
                        nContext = [
                          { role: "system", content: newChat },
                          { role: "user", content: buttonName },
                        ];
                      } else {
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                      }

                      setContext(nContext);
                      const userMessage = {
                        text: buttonName,
                        user: true,
                      };
                      const aiResponse = {
                        text: "loading...",
                        user: false,
                      };
                      if (buttonName.includes("New")) {
                        setMessages([userMessage, aiResponse]);
                      } else {
                        setMessages([...messages, userMessage, aiResponse]);
                      }

                      getCompletionFromMessages(nContext);
                      SetbuttonNames([]);
                    }}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* {textBeforeButtons !== "" && (
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                {buttonNames.map((buttonName, index) => (
                  <button
                    className="send-button"
                    key={index}
                    onClick={() => handleButtonClick(buttonName)}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
            </div>
          )} */}
          <div ref={lastMessageRef}></div>
        </div>

        <div className="input-container" style={{ padding: "20px" }}>
          <input
            type="text"
            placeholder="Type your message..."
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            className="input-text"
          />
          <button onClick={handleSendMessage} className="send-button">
            Send
          </button>
        </div>
      </Col>
    </div>
  );
};

export default AHSChatBot;
