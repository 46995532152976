import React, { useState, useContext, useEffect } from "react";
import { Configuration, OpenAIApi } from "openai";
import { Card, Row, Col, Button } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { BiSend, BiClipboard, BiCheck } from "react-icons/bi";
import AuthContext from "./auth/AuthContext";
import Plot from "react-plotly.js";
import Table from "./Table";
import "./ChatbotApp.css";
import { FiRefreshCw } from "react-icons/fi";
import { useNavigate } from "react-router";
import AIChatBot from "./auth/AIChatBot.js";
import AHSChatBot from "./auth/AHSChatBot";

const ChatbotApp = () => {
  const navigate = useNavigate();
  const configuration = new Configuration({
    apiKey: "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",
  });

  const openai = new OpenAIApi(configuration);
  const { chatMsg, setChatMsgfun } = useContext(AuthContext);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [searchType, setSearchType] = useState(localStorage.getItem("sidebar"));
  const [showCurrentResponse, setShowCurrentResponse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [columnNames, setcolumnNames] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeChart, setActiveChart] = useState("");
  const [selectedDB, setSelectedDB] = useState("Employees");
  const [selectedColumns, setSelectedColumns] = useState(columnNames);
  const handleColumnSelect = (column) => {
    if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns.filter((col) => col !== column));
    } else if (selectedColumns.length < 2) {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  const loggedInUser = sessionStorage.getItem("currentUser");
  let fullName = "";
  let empNo = "";
  let empType = "";

  try {
    const userObj = JSON.parse(loggedInUser);
    console.log("userObj: ", userObj);
    fullName = userObj.full_name;
    if (fullName.includes("nan")) {
      fullName = fullName.replace("nan", "");
    }
    empNo = userObj.emp_no;
    empType = userObj.employeeType;
  } catch (error) {
    console.error("Error parsing user data:", error);
  }

  console.log("Full Name:", fullName);
  console.log("empType:", empType);
  console.log("empNo:", empNo);
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  const handleChartChange = (chartType) => {
    setActiveChart(chartType);
  };
  const resetData = () => {
    setTableData([]);
    setActiveChart("");
    setResponse("");
    setSelectedColumns("");
  };
  const resetAllData = () => {
    setTableData([]);
    setActiveChart("");
    setResponse("");
    setSelectedColumns("");
    setPrompt("");
  };
  const handleInputChange = (e) => {
    setPrompt(e.target.value);
    setIsCopied(false);
    resetData();
  };
  const handleCopyContent = () => {
    const textarea = document.createElement("textarea");
    textarea.value = response;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setIsCopied(true);
  };

  const handleSubmitapi = async (e) => {
    e.preventDefault();
    setResponse("");
    setIsLoading(true);

    try {
      if (searchType === "general") {
        const response = await openai.createCompletion({
          model: "text-davinci-003",
          prompt: prompt,
          temperature: 0.6,
          max_tokens: 2000,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        });

        const { choices } = response.data;
        if (choices && choices.length > 0) {
          const generatedText = choices[0].text.trim();
          setResponse(generatedText);
          const obj = { request: prompt, response: generatedText };
          setChatMsgfun(obj);
        } else {
          console.log("No completion choices found.");
        }
      } else if (searchType === "project") {
        const url =
          "https://openaitimesheet.azurewebsites.net/get_answers_security";
        const body = {
          prompt: prompt,
          empNo: empNo,
          empType: empType,
          fullName: fullName,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log("dff", data.df);
        setResponse(data.answer);
        setTableData(data.df);
        const columnNames = Object.keys(data.df[0]);
        console.log("columnNames", columnNames);
        setcolumnNames(columnNames);

        const transposedRows = columnNames.map((columnName) =>
          data.df.map((row) => row[columnName])
        );
        console.log("transposedRows", transposedRows);
        setRows(transposedRows);

        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      } else if (searchType === "empproject") {
        const url = "https://openaitimesheet.azurewebsites.net/get_answer_emp";
        const body = {
          prompt: prompt,
          database: selectedDB,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log("dff", data.df);
        setResponse(data.answer);
        setTableData(data.df);
        const columnNames = Object.keys(data.df[0]);
        console.log("columnNames", columnNames);
        setcolumnNames(columnNames);

        const transposedRows = columnNames.map((columnName) =>
          data.df.map((row) => row[columnName])
        );
        setRows(transposedRows);
        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      } else if (searchType === "pdf") {
        const url = "https://openaitimesheet.azurewebsites.net/pdf_search";
        const body = {
          question: prompt,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setResponse(data.answer);
        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      } else if (searchType === "multiplepdf") {
        const url = "https://openaitimesheet.azurewebsites.net/invoicepdf";
        const body = {
          question: prompt,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log("dff", data.df);
        setResponse(data.answer);
        const tableData = data.df.slice(1);
        setTableData(tableData);
        const columnNames = Object.keys(data.df[1]);
        console.log("columnNames", columnNames);
        setcolumnNames(columnNames);

        const transposedRows = columnNames.map((columnName) =>
          data.df.map((row) => row[columnName])
        );
        console.log("transposedRows", transposedRows);
        setRows(transposedRows);

        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      } else if (searchType === "google") {
        const url = "https://openaitimesheet.azurewebsites.net/googlesearch";
        const body = {
          question: prompt,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setResponse(data.answer);
        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      } else if (searchType === "wikipedia") {
        const url = "https://openaitimesheet.azurewebsites.net/wikisearch";
        const body = {
          question: prompt,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            referrerPolicy: "no-referrer-when-downgrade",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setResponse(data.answer);
        const obj = { request: prompt, response: data.answer };
        setChatMsgfun(obj);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setIsLoading(false);
  };

  const handleLogout = (e) => {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    console.log("object");
    window.location.href = "/";
  };

  const renderBarChart = () => {
    if (!tableData || tableData.length === 0) {
      return null;
    }
    if (selectedColumns.length === 2) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mb-10">
            <Plot
              data={[
                {
                  x: tableData.map((row) => row[selectedColumns[0]]),
                  y: tableData.map((row) => row[selectedColumns[1]]),
                  type: "bar",
                  mode: "lines+markers",
                  marker: { color: "blue" },
                },
              ]}
              layout={{
                title: "Bar Plot",
                xaxis: { title: selectedColumns[0] },
                yaxis: { title: selectedColumns[1] },
              }}
            />
          </div>
        </div>
      );
    }
  };
  const renderPieChart = () => {
    if (!tableData || tableData.length === 0) {
      return null;
    }
    if (selectedColumns.length === 2) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mb-10">
            <Plot
              data={[
                {
                  values: tableData.map((row) => row[selectedColumns[1]]),
                  labels: tableData.map((row) => row[selectedColumns[0]]),
                  type: "pie",
                },
              ]}
              layout={{
                title: "Pie Chart",
              }}
            />
          </div>
        </div>
      );
    }
  };
  const renderLineChart = () => {
    if (!tableData || tableData.length === 0) {
      return null;
    }
    if (selectedColumns.length === 2) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="mb-10">
            <Plot
              data={[
                {
                  x: tableData.map((row) => row[selectedColumns[0]]),
                  y: tableData.map((row) => row[selectedColumns[1]]),
                  type: "scatter",
                  mode: "lines+markers",
                },
              ]}
              layout={{
                title: "Line Chart",
                xaxis: {
                  title: selectedColumns[0],
                },
                yaxis: {
                  title: selectedColumns[1],
                },
              }}
            />
          </div>
        </div>
      );
    }
  };

  const renderTable = () => {
    if (tableData && tableData.length > 0) {
      return (
        <div
          id="table-container"
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            overflowX: "auto",
            margin: "auto",
          }}
        >
          <Table
            data={tableData}
            columns={columnNames}
            selectedColumns={selectedColumns}
            onColumnSelect={handleColumnSelect}
          />
        </div>
      );
    }
    return null;
  };

  const renderTableButtons = () => {
    if (tableData && tableData.length > 0 && selectedColumns.length === 2) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="d-flex p-3 ">
            <Button
              className="p-2 m-2 btn-primary"
              onClick={() => handleChartChange("bar")}
            >
              Bar Chart
            </Button>
            <Button
              className="p-2 m-2"
              onClick={() => handleChartChange("pie")}
            >
              Pie Chart
            </Button>
            <Button
              className="p-2 m-2"
              onClick={() => handleChartChange("line")}
            >
              Line Chart
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  const displayData = chatMsg.map((chat, index) => (
    <div key={index}>
      <h6 style={{ color: "red" }}>Request: {chat.request}</h6>
      <h6 style={{ color: "green" }}>Response: {chat.response}</h6>
    </div>
  ));
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmitapi(event);
    }
  };

  //   return (
  //     <Row style={{ height: "100%" }}>
  //       <Row className="p-0">
  //         <Col
  //           className="col-lg-12"
  //           style={{
  //             backgroundColor: "#0A3161",
  //             color: "#FFFFF",
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <div className="col-lg-2">
  //             <img
  //               src={require("./assets/img/SST Logo.jpg")}
  //               className="card-img-top"
  //               alt="loginimage"
  //               style={{
  //                 zIndex: -9999,
  //                 position: "static",
  //                 width: "150px",
  //                 marginLeft: "50px",
  //                 height: "56px",
  //               }}
  //             />
  //           </div>
  //           <Typography variant="h5" color="common.white" margin="auto">
  //             <div>ChatGPT Integration With Application</div>
  //           </Typography>
  //           <div className="w-auto">
  //             <div
  //               style={{
  //                 color: "white",
  //               }}
  //             >
  //               Hello,
  //             </div>
  //             <div
  //               style={{
  //                 color: "white",
  //               }}
  //             >
  //               {fullName}
  //             </div>
  //             <a
  //               onClick={() => {
  //                 localStorage.removeItem("currentUser");
  //                 setSearchType("logout");
  //                 handleLogout();
  //               }}
  //               style={{
  //                 color: "white",
  //                 cursor: "pointer",
  //                 textDecoration: "underline",
  //               }}
  //             >
  //               Logout
  //             </a>
  //           </div>
  //         </Col>
  //       </Row>
  //       <Row style={{ padding: "0" }}>
  //         <Col
  //           lg="2"
  //           style={{
  //             height: "100%",
  //             position: "relative",
  //             display: "flex",
  //             backgroundColor: "rgb(12 57 111)",
  //             flexDirection: "column",
  //             paddingBottom: "118px",
  //           }}
  //         >
  //           <button
  //             className={`button ${searchType === "general" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("general");
  //             }}
  //           >
  //             General Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "project" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("project");
  //             }}
  //           >
  //             Project Specific Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "empproject" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("empproject");
  //             }}
  //           >
  //             External DB Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "pdf" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("pdf");
  //             }}
  //           >
  //             Insurance PDF Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "multiplepdf" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("multiplepdf");
  //             }}
  //           >
  //             Invoices PDFs Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "google" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("google");
  //             }}
  //           >
  //             Google Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "wikipedia" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "wikipedia");
  //               setSearchType("wikipedia");
  //             }}
  //           >
  //             Wikipedia Search
  //           </button>
  //           <button
  //             className={`button ${searchType === "chatbot" ? "active" : ""}`}
  //             onClick={() => {
  //               localStorage.setItem("sidebar", "chatbot");
  //               setSearchType("chatbot");
  //             }}
  //           >
  //             AIChatBot
  //           </button>
  //         </Col>

  //         <Col
  //           lg="10"
  //           style={{
  //             flex: "0 0 auto",
  //             width: "83.333333%",
  //             height: "687px",
  //           }}
  //         >
  //           {localStorage.getItem("sidebar") === "chatbot" && <AIChatBot />}
  //           {searchType === "empproject" && (
  //             <div className="select-database">
  //               <label>
  //                 Select Database:
  //                 <select
  //                   value={selectedDB}
  //                   onChange={(e) => setSelectedDB(e.target.value)}
  //                 >
  //                   <option value="Employees">Employees</option>
  //                   <option value="Sakila">Sakila</option>
  //                 </select>
  //               </label>
  //             </div>
  //           )}
  //           {localStorage.getItem("sidebar") === "wikipedia" && (
  //             <button
  //               onClick={() => setShowCurrentResponse(!showCurrentResponse)}
  //               style={{
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //                 color: "rgb(12 57 111)",
  //                 marginLeft: "1100px",
  //               }}
  //             >
  //               {showCurrentResponse ? (
  //                 <>
  //                   <BiCheck size={20} />
  //                   <span>Show History</span>
  //                 </>
  //               ) : (
  //                 <>
  //                   <BiClipboard size={20} />
  //                   <span>Show Current Response</span>
  //                 </>
  //               )}
  //             </button>
  //           )}
  //           {localStorage.getItem("sidebar") === "wikipedia" && (
  //             <>
  //               <div
  //                 style={{
  //                   flexDirection: "column",
  //                   alignItems: "center",
  //                   height: "68%",
  //                   overflowX: "hidden",
  //                   width: "100%",
  //                   background: "#e5e1fc",
  //                   position: "relative",
  //                   marginBottom: "20px",
  //                 }}
  //               >
  //                 {response && (
  //                   <div
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "space-between",
  //                       alignItems: "center",
  //                       padding: "10px",
  //                       borderBottom: "1px solid #ccc",
  //                       backgroundColor: "rgb(12 57 111)",
  //                       width: "100%",
  //                       color: "white",
  //                       fontSize: "17px",
  //                       overflowY: "auto",
  //                       maxHeight: "500px",
  //                     }}
  //                   >
  //                     <div>
  //                       <span>
  //                         {showCurrentResponse ? "Response" : "Response History"}
  //                       </span>
  //                     </div>
  //                     <div>
  //                       <button
  //                         onClick={handleCopyContent}
  //                         style={{
  //                           background: "none",
  //                           border: "none",
  //                           cursor: "pointer",
  //                           color: "white",
  //                           margin: "-4px",
  //                         }}
  //                       >
  //                         {isCopied ? (
  //                           <>
  //                             <BiCheck size={20} />
  //                             <span>Copied!</span>
  //                           </>
  //                         ) : (
  //                           <>
  //                             <BiClipboard
  //                               size={20}
  //                               style={{
  //                                 marginRight: "3px !important",
  //                                 margin: "-3px",
  //                               }}
  //                             />
  //                             <span>Copy Code</span>
  //                           </>
  //                         )}
  //                       </button>
  //                     </div>
  //                   </div>
  //                 )}

  //                 <div style={{ margin: "10px", marginTop: "10px" }}>
  //                   {isLoading ? (
  //                     <div
  //                       style={{
  //                         justifyContent: "center",
  //                         alignItems: "center",
  //                         display: "flex",
  //                       }}
  //                     >
  //                       Loading...
  //                     </div>
  //                   ) : showCurrentResponse ? (
  //                     <pre
  //                       style={{
  //                         whiteSpace: "pre-wrap",
  //                         fontFamily: "Arial",
  //                         fontSize: "16px",
  //                         textWrap: "wrap",
  //                       }}
  //                     >
  //                       {response}
  //                     </pre>
  //                   ) : (
  //                     <pre
  //                       style={{
  //                         whiteSpace: "pre-wrap",
  //                         overflowY: "auto",
  //                         height: "100%",
  //                         fontFamily: "Arial",
  //                         fontSize: "18px",
  //                         alignItems: "center",
  //                       }}
  //                     >
  //                       {displayData}
  //                     </pre>
  //                   )}
  //                 </div>
  //                 {renderTable()}
  //                 {renderTableButtons()}
  //                 <div>
  //                   {activeChart === "bar" && renderBarChart()}
  //                   {activeChart === "pie" && renderPieChart()}
  //                   {activeChart === "line" && renderLineChart()}
  //                 </div>
  //               </div>

  //               <form
  //                 onSubmit={handleSubmitapi}
  //                 style={{
  //                   display: "flex",
  //                   justifyContent: "center",
  //                 }}
  //               >
  //                 <div style={{ display: "flex", alignItems: "center" }}>
  //                   <textarea
  //                     id="prompt"
  //                     value={prompt}
  //                     placeholder="Ask the Question"
  //                     onChange={handleInputChange}
  //                     onKeyPress={handleKeyPress}
  //                     required
  //                     style={{
  //                       width: "600px",
  //                       marginRight: "10px",
  //                       background: "#f0f0f0",
  //                       borderRadius: "10px",
  //                       border: "1px solid #ccc",
  //                       outline: "none",
  //                       resize: "vertical",
  //                       whiteSpace: "pre-line",
  //                       overflowY: "hidden",
  //                       alignItems: "center",
  //                       display: "flex",
  //                       boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  //                     }}
  //                   />
  //                   <button
  //                     type="submit"
  //                     style={{
  //                       background: "none",
  //                       border: "none",
  //                       cursor: "pointer",
  //                       color: "blue",
  //                       display: "flex",
  //                       alignItems: "center",
  //                       marginLeft: "-48px",
  //                     }}
  //                   >
  //                     <BiSend size={20} style={{ marginRight: "5px" }} />
  //                   </button>
  //                 </div>

  //                 <div
  //                   style={{ display: "flex", justifyContent: "space-between" }}
  //                 >
  //                   <button
  //                     type="submit"
  //                     onClick={resetAllData}
  //                     style={{
  //                       background: "none",
  //                       border: "none",
  //                       cursor: "pointer",
  //                       color: "blue",
  //                       display: "flex",
  //                       alignItems: "center",
  //                     }}
  //                   >
  //                     <FiRefreshCw size={20} style={{ marginRight: "5px" }} />
  //                   </button>
  //                 </div>
  //               </form>
  //             </>
  //           )}
  //         </Col>
  //       </Row>
  //     </Row>
  //   );
  // };

  // export default ChatbotApp;

  return (
    <Row style={{ height: "100%", position: "fixed" }}>
      <Row className="p-0">
        <Col
          className="col-lg-12"
          style={{
            // position: "sticky", // Set the position to fixed
            // top: 0,
            backgroundColor: "#0A3161",
            color: "#FFFFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "1757px",

            // marginTop: "40px",
          }}
        >
          <div className="col-lg-2">
            <img
              src={require("./assets/img/SST Logo.jpg")}
              className="card-img-top"
              alt="loginimage"
              style={{
                zIndex: -9999,
                position: "static",
                width: "150px",
                marginLeft: "50px",
                height: "56px",
              }}
            />
          </div>
          <Typography variant="h5" color="common.white" margin="auto">
            <div>ChatGPT Integration With Application</div>
          </Typography>
          <div className="w-auto">
            <div
              style={{
                color: "white",
              }}
            >
              Hello,
            </div>
            <div
              style={{
                color: "white",
              }}
            >
              {fullName}
            </div>
            <a
              onClick={() => {
                localStorage.removeItem("currentUser");
                setSearchType("logout");
                handleLogout();
              }}
              style={{
                color: "white",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Logout
            </a>
          </div>
        </Col>
      </Row>
      <Row style={{ padding: "0" }}>
        <Col
          lg="2"
          style={{
            height: "100%",
            position: "relative",
            display: "flex",
            backgroundColor: "rgb(12 57 111)",
            flexDirection: "column",
            paddingBottom: "118px",
          }}
        >
          {/* <button
            className={`button ${searchType === "AHSchatbot" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "AHSchatbot");
              setSearchType("AHSchatbot");
            }}
          >
            AHS AIChatBot
          </button> */}
          <button
            className={`button ${searchType === "general" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("general");
            }}
          >
            General Search
          </button>
          <button
            className={`button ${searchType === "project" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("project");
            }}
          >
            Project Specific Search
          </button>
          <button
            className={`button ${searchType === "empproject" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("empproject");
            }}
          >
            External DB Search
          </button>
          <button
            className={`button ${searchType === "pdf" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("pdf");
            }}
          >
            Insurance PDF Search
          </button>
          <button
            className={`button ${searchType === "multiplepdf" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("multiplepdf");
            }}
          >
            Invoices PDFs Search
          </button>
          <button
            className={`button ${searchType === "google" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("google");
            }}
          >
            Google Search
          </button>
          <button
            className={`button ${searchType === "wikipedia" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "wikipedia");
              setSearchType("wikipedia");
            }}
          >
            Wikipedia Search
          </button>
          <button
            className={`button ${searchType === "chatbot" ? "active" : ""}`}
            onClick={() => {
              localStorage.setItem("sidebar", "chatbot");
              setSearchType("chatbot");
            }}
          >
            AIChatBot
          </button>
        </Col>

        <Col
          lg="10"
          style={{
            flex: "0 0 auto",
            width: "83.333333%",
            height: "687px",
          }}
        >
          {localStorage.getItem("sidebar") === "AHSchatbot" && <AHSChatBot />}
          {localStorage.getItem("sidebar") === "chatbot" && <AIChatBot />}
          {searchType === "empproject" && (
            <div className="select-database">
              <label>
                Select Database:
                <select
                  value={selectedDB}
                  onChange={(e) => setSelectedDB(e.target.value)}
                >
                  <option value="Employees">Employees</option>
                  <option value="Sakila">Sakila</option>
                </select>
              </label>
            </div>
          )}
          {localStorage.getItem("sidebar") === "wikipedia" && (
            <button
              onClick={() => setShowCurrentResponse(!showCurrentResponse)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "rgb(12 57 111)",
                marginLeft: "1100px",
              }}
            >
              {showCurrentResponse ? (
                <>
                  <BiCheck size={20} />
                  <span>Show History</span>
                </>
              ) : (
                <>
                  <BiClipboard size={20} />
                  <span>Show Current Response</span>
                </>
              )}
            </button>
          )}
          {localStorage.getItem("sidebar") === "wikipedia" && (
            <>
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  height: "68%",
                  overflowX: "hidden",
                  width: "100%",
                  background: "#e5e1fc",
                  position: "relative",
                  marginBottom: "20px",
                }}
              >
                {response && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderBottom: "1px solid #ccc",
                      backgroundColor: "rgb(12 57 111)",
                      width: "100%",
                      color: "white",
                      fontSize: "17px",
                      overflowY: "auto",
                      maxHeight: "500px",
                    }}
                  >
                    <div>
                      <span>
                        {showCurrentResponse ? "Response" : "Response History"}
                      </span>
                    </div>
                    <div>
                      <button
                        onClick={handleCopyContent}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          color: "white",
                          margin: "-4px",
                        }}
                      >
                        {isCopied ? (
                          <>
                            <BiCheck size={20} />
                            <span>Copied!</span>
                          </>
                        ) : (
                          <>
                            <BiClipboard
                              size={20}
                              style={{
                                marginRight: "3px !important",
                                margin: "-3px",
                              }}
                            />
                            <span>Copy Code</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}

                <div style={{ margin: "10px", marginTop: "10px" }}>
                  {isLoading ? (
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Loading...
                    </div>
                  ) : showCurrentResponse ? (
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        textWrap: "wrap",
                      }}
                    >
                      {response}
                    </pre>
                  ) : (
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        overflowY: "auto",
                        height: "100%",
                        fontFamily: "Arial",
                        fontSize: "18px",
                        alignItems: "center",
                      }}
                    >
                      {displayData}
                    </pre>
                  )}
                </div>
                {renderTable()}
                {renderTableButtons()}
                <div>
                  {activeChart === "bar" && renderBarChart()}
                  {activeChart === "pie" && renderPieChart()}
                  {activeChart === "line" && renderLineChart()}
                </div>
              </div>

              <form
                onSubmit={handleSubmitapi}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <textarea
                    id="prompt"
                    value={prompt}
                    placeholder="Ask the Question"
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    required
                    style={{
                      width: "600px",
                      marginRight: "10px",
                      background: "#f0f0f0",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      outline: "none",
                      resize: "vertical",
                      whiteSpace: "pre-line",
                      overflowY: "hidden",
                      alignItems: "center",
                      display: "flex",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <button
                    type="submit"
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "blue",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "-48px",
                    }}
                  >
                    <BiSend size={20} style={{ marginRight: "5px" }} />
                  </button>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    type="submit"
                    onClick={resetAllData}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "blue",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FiRefreshCw size={20} style={{ marginRight: "5px" }} />
                  </button>
                </div>
              </form>
            </>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default ChatbotApp;
