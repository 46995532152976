import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import { Configuration, OpenAIApi } from "openai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AIChatBot.css";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
let demo = "";
let leavedata = "";
let docdata = "";
let filename = "";
let DOCjsonObject;
const todayDate = new Date().toDateString();
console.log("todayDate", todayDate);

const AIChatBot = () => {
  const configuration = new Configuration({
    apiKey: "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",
  });
  const blobSasUrl =
    "https://blobtimesheet.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-18T12:14:34Z&st=2023-08-18T04:14:34Z&spr=https&sig=jIZ%2FHW0Eqvd%2Fv1SQ4ObCSCVFhDGdnLvX21OwMT9smHY%3D";

  // Create a BlobServiceClient instance using the SAS URL

  const blobServiceClient = new BlobServiceClient(blobSasUrl);

  const openai = new OpenAIApi(configuration);
  const [response, setResponse] = useState("Hi");
  const [assistant, SetAssistant] = useState("");
  const [messages, setMessages] = useState([]);
  const [textBeforeButtons, SettextBeforeButtons] = useState("");
  const [buttonNames, SetbuttonNames] = useState([]);
  const [dropdownNames, SetDropdownNames] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [dropdownClicked, setDropdownClicked] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileUpload, SetShowFileUpload] = useState(false);
  const [leaveList, SetLeaveList] = useState([]);
  const [docList, SetDocList] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const loggedInUser = sessionStorage.getItem("currentUser");
  const chatContainerRef = useRef();
  const lastMessageRef = useRef();

  let empNo = "";
  let fullName = "";
  try {
    const userObj = JSON.parse(loggedInUser);
    console.log("userObj: ", userObj);
    empNo = userObj.emp_no;
    fullName = userObj.full_name;
    if (fullName.includes("nan")) {
      fullName = fullName.replace("nan", "");
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }
  console.log("employee Number:", empNo);
  console.log("Full Name:", fullName);
  // const final_answer =
  //   "The list of holidays and its details are: (1, datetime.date(2023, 1, 26), 'Thursday', 'Republic Day', 'Holiday'), (2, datetime.date(2023, 3, 22), 'Wednesday', 'Gudi Padwa', 'Holiday'), (3, datetime.date(2023, 5, 1), 'Monday', 'Maharashtra Day', 'Holiday'), (4, datetime.date(2023, 8, 15), 'Tuesday', 'Independence Day', 'Holiday'), (5, datetime.date(2023, 8, 30), 'Wednesday', 'Raksha Bandhan', 'Holiday'), (6, datetime.date(2023, 9, 28), 'Thursday', 'Anant Chaturdashi', '2 hr Delay'), (7, datetime.date(2023, 10, 24), 'Tuesday', 'Vijaya Dashami', 'Holiday'), (8, datetime.date(2023, 11, 13), 'Monday', 'Diwali', 'Holiday'), (9, datetime.date(2023, 11, 14),'Tuesday','Padwa','Holiday'";

  const final_answer = `[
    {"id": 1, "date": "2023-01-26", "day": "Thursday", "occasion": "Republic Day", "type": "Holiday"},
    {"id": 2, "date": "2023-03-22", "day": "Wednesday", "occasion": "Gudi Padwa", "type": "Holiday"},
    {"id": 3, "date": "2023-05-01", "day": "Monday", "occasion": "Maharashtra Day", "type": "Holiday"},
    {"id": 4, "date": "2023-08-15", "day": "Tuesday", "occasion": "Independence Day", "type": "Holiday"},
    {"id": 5, "date": "2023-08-30", "day": "Wednesday", "occasion": "Raksha Bandhan", "type": "Holiday"},
    {"id": 6, "date": "2023-09-28", "day": "Thursday", "occasion": "Anant Chaturdashi", "type": "2 hr Delay"},
    {"id": 7, "date": "2023-10-24", "day": "Tuesday", "occasion": "Vijaya Dashami", "type": "Holiday"},
    {"id": 8, "date": "2023-11-13", "day": "Monday", "occasion": "Diwali", "type": "Holiday"},
    {"id": 9, "date": "2023-11-14", "day": "Tuesday", "occasion": "Padwa", "type": "Holiday"}
]`;

  const extractButtonNames = (text) => {
    const startIndex = text.indexOf("Buttons: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };
  const extractDropdownopt = (text) => {
    const startIndex = text.indexOf("Dropdown: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };
  console.log("buttonClicked", buttonClicked);

  const extractleavejson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    console.log("jsonString in function", jsonString);

    // Parse the JSON string
    const leavejson = jsonString;
    const jsonObject = JSON.parse(jsonString);
    console.log("jsonObject..", jsonObject);
    try {
      const res = await axios.post(
        "https://web-timesheet-backend-devops.azurewebsites.net/api/emp_leave",
        jsonObject
      );
      console.log("Res:-", res);
    } catch (error) {
      console.log("error:-", error);
    }
    let updatedleave = await leaves_listapi();
    console.log("updatedleave", updatedleave);
    return leavejson;
  };
  const extractdocjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    console.log("DOCjsonString in function", jsonString);

    // Parse the JSON string
    // const leavejson = jsonString;
    DOCjsonObject = JSON.parse(jsonString);
    console.log("DOCjsonObject..", DOCjsonObject);
  };
  useEffect(() => {
    leaves_listapi();
    doc_listapi();
  }, []);
  // let leaves_list
  const leaves_listapi = async () => {
    try {
      const res = await axios.get(
        `https://web-timesheet-backend-devops.azurewebsites.net/api/emp_leave/leaveStatusChatbot/${empNo}`
      );
      console.log("Res:-", res.data.data);
      SetLeaveList(res.data.data);
      leavedata = JSON.stringify(res.data.data);
      console.log("leavedata in try", leavedata);
      return leavedata;
    } catch (error) {
      console.log("error:-", error);
    }
  };
  const doc_listapi = async () => {
    console.log("doc api called");
    try {
      const res = await axios.get(
        `https://web-timesheet-backend-devops.azurewebsites.net/api/employee_details/getdocumentsbyempno/${empNo}`
      );
      console.log("Res:-", res.data.data);
      SetDocList(res.data.data);
      docdata = JSON.stringify(res.data.data);
      console.log("docdata in try", docdata);
      return docdata;
    } catch (error) {
      console.log("error:-", error);
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    filename = file.name;
    console.log("filename", filename);
    setSelectedFile(file);
    SetShowFileUpload(false);
  };
  const handleUpload = async () => {
    let docURL = "";

    let folderName = "";
    console.log("DOCjsonObject in handleupload", DOCjsonObject);
    const names = fullName.split(" ");
    if (names.length >= 2) {
      const firstName = names[0];
      const lastName = names[names.length - 1];
      folderName = `${firstName}${lastName}`;
      // return folderName;
    } else {
      folderName = "";
    }

    const containerClient = blobServiceClient.getContainerClient(
      `sstemployeedocument/${folderName}`
    );
    let name = selectedFile.name.split(".")[0];
    let uploadDate = new Date().toISOString().slice(0, 10);
    let type = selectedFile.type.split("/")[1];
    let fileFormat =
      folderName + "_" + name + "_" + type + "_" + uploadDate + "." + type;

    const blockBlobClient = containerClient.getBlockBlobClient(
      `myDocuments/${fileFormat}`
    );
    await blockBlobClient.uploadBrowserData(selectedFile);
    docURL = blockBlobClient.url;
    let obj = {
      document_id: 10,
      document_url: docURL,
      emp_no: DOCjsonObject.emp_no,
      folder_id: DOCjsonObject.folder_id,
      remark: DOCjsonObject.remark,
    };
    console.log("Obj: ", obj);
    const res = await axios.post(
      "https://web-timesheet-backend-devops.azurewebsites.net/api/employee_details/uploaddocuments",
      obj
    );
    console.log("res", res);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("date:-", date);
    console.log("In ui function:-", typeof date);

    updateUI(date);
  };
  const handleDropdownChange = (event) => {
    setDropdownClicked(event.target.value);
    demo = event.target.value;
    let nContext = [...context, { role: "user", content: event.target.value }];
    setContext(nContext);
    const userMessage = {
      text: event.target.value,
      user: true,
    };
    const aiResponse = {
      text: "loading...",
      user: false,
    };
    setMessages([...messages, userMessage, aiResponse]);
    getCompletionFromMessages(nContext);
    SetDropdownNames([]);
  };
  console.log("dropdown clicked", dropdownClicked);
  const updateUI = (date) => {
    const datenew = new Date(date);
    const year = datenew.getFullYear();
    const month = String(datenew.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(datenew.getDate()).padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    console.log("In ui function111:-", typeof formattedDate);

    demo = formattedDate;
    let nContext = [...context, { role: "user", content: formattedDate }];

    setContext(nContext);
    const userMessage = {
      text: formattedDate,
      user: true,
    };
    const aiResponse = {
      text: "loading...",
      user: false,
    };
    setMessages([...messages, userMessage, aiResponse]);
    getCompletionFromMessages(nContext);
    setShowDatePicker(false);
  };
  console.log("selectedDate", selectedDate);
  console.log("buttonClicked", buttonClicked);
  // Define separate contexts for each interaction
  const initialContext = [
    {
      role: "system",
      content: `
         You are a friendly Employee Assistance ChatBot, providing information about holidays or leave application process.
        first greet the employee and ask like do you have any question about holidays, documents and leave applications.also provide Buttons array(note: Buttons B should be capital) Three button one for list of holiday and one for Apply leave and My Documents like this, Buttons: ['List of Holidays', ' Apply leave', 'My Documents'] \     
        `,
    },
  ];

  console.log("leavedata in outside", leavedata);
  console.log("docdata in outside", docdata);
  console.log("selectedFile", selectedFile);
  // let holidayContext=
  const newChat = `
  You are a friendly Employee Assistance ChatBot, providing information about holidays or leave application process.
 first greet the employee and ask like do you have any question about holidays, documents and leave applications.also provide Buttons array(note: Buttons B should be capital) Three button one for list of holiday and one for Apply leave and My Documents like this, Buttons: ['List of Holidays', ' Apply leave', 'My Documents'] \     
 `;
  const holidayContext = `
        You are the Employee holiday chatbot,here to assiet with holiday related questions.
    when employee in between ask any question not relate to holiday ,ask employee do you want to close the holiday context
     Holiday Details: '''\`${final_answer}\`'''
    also calculate based on current date which is ${todayDate} if required for example: when the user asks for an upcoming holiday
    You respond in a short, conversational and friendly style.
    Holiday Details: '''\`${final_answer}\`'''
    also calculate based on current date which is ${todayDate} if required for example: when the user asks for an upcoming holiday on related to holiday. 
    when the employee asks about the list of holidays give details of all holidays.
    when the employee asks about the list of upcoming holidays calculate based on the ${todayDate}  and give details.\
    if the month does not contain holidays then say there are no holidays in that month.
    if the employee asks other than holiday and leaves question ex: timesheet, salary slip etc then say "I don't have this information. do you want to exit this conversation. also provide   Buttons array(note: Buttons B should be capital) two button one for Yes and one for No, Buttons: ['Yes', 'No']"\
    Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['Yes', 'No'] only shown this button only when you ask the question  Do you want to exit this conversation?
    At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;
  // const docContext = `You are the Employee documents assistant chatbot, here to assiet with documents related questions.
  //   Employee Documents Details are:  ${JSON.stringify(docList)}
  //   if Employee ask about list of documents or show me my all documents then provide document names.\
  //   if employee asks about specific document then provide him details like name of document, created date and provide and say "you you want to view or download click on this URL" provide doc_url in html anchor tag which will target _blank example <a  target="_blank" rel="noopener noreferrer">www.google.com</a>.\
  //   if employee ask i want to upload document then ask which document you want to upload. also provide Buttons array(note : Buttons B should be capital) one for upload file like this, Buttons:['Upload File']
  //   Feel free to respond conversationally and guide the employee through the process.
  //   At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;
  const docContext = `You are the Employee documents assistant chatbot, here to assist with documents related questions.
    Employee Documents Details are:  ${JSON.stringify(docList)}
    if Employee asks about the list of documents or "show me my all documents," then provide specific document names only.\
    if an employee asks about a specific document,then provide details like the name of the document, created date, and say "you want to view or download, click on this URL" provide doc_url in an HTML anchor tag which will target _blank. For example: <a target="_blank" rel="noopener noreferrer">www.google.com</a>.\
    if the employee asks "I want to upload a document," then
    You can ask for the following information:
    -Folder type (e.g., My Documents, Academics, Certificates, Bank Documents): note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['My Documents', 'Academics', 'Certificates', 'Bank Documents']
    -ask for Remark for document\
    -ask employee to upload file, like "choose file to upload document.note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Continue', 'Cancel']\
    Ask each question one by one  and store in respective json key.\
    if employee say "Continue" take emp_no as ${empNo} and document_url as filename ${filename}
    remember every employee input and add to respective json key given in format below.\
    calculate folder_id key from Type of folder if folder type is My Documents then 1, Academics is 2, Certificates is 3, Bank Documents is 4.\
    Json format should look like this and store in variable called "doc-data",
    "doc-data":    {
      "emp_no": "12345",
      "folder_id": "vacation",
      "remark": "I need a break to recharge."
      "document_url:"pan-card.pdf"
    }   
    and ask please check all details and Do you want to upload document? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Upload', 'Cancel']
    if employee say upload then say "Document uploaded successfully", if cancel then ask "do you need any oyher help"
    Feel free to respond conversationally and guide the employee through the process.
    At the end ask the employee "Do you want to end this conversation or start a new conversation?" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;

  // const docContext = `
  // You are the Employee documents assistant chatbot, here to assist with documents related questions.
  //     Employee Documents Details are:  ${JSON.stringify(docList)}
  //     if Employee asks about the list of documents or "show me my all documents," then provide document names.\
  //     if an employee asks about a specific document,then provide details like the name of the document, created date, and say "you want to view or download, click on this URL" provide doc_url in an HTML anchor tag which will target _blank. For example: <a target="_blank" rel="noopener noreferrer">www.google.com</a>.\
  //     if the employee asks "I want to upload a document," then
  //     You can ask for the following information:
  // - Folder type (e.g., My Documents, Academics, Certificates, Bank Documents): note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['My Documents', 'Academics', 'Certificates', 'Bank Documents']
  // - Remark for document
  // AI means you provide upload file button control to the employee to upload their document :
  // Ask each question one by one and store in respective JSON key. and take emp_no as ${empNo} \
  // remember every employee input and add to respective JSON key given in the format below.\
  // calculate folder_id key from the Type of folder if folder type is My Documents then 1, Academics is 2, Certificates is 3, Bank Documents is 4.\
  // JSON format should look like this and store in a variable called "data" and ask, please check all details and Do you want to upload the document? note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes', 'No']
  // "data":    {
  //   "emp_no": "${empNo}",
  //   "folder_id": "vacation", // calculate folderId based on the selected folder type
  //   "remark": "I want vacation"
  //   "file": "${uploadFile ? "uploaded file details" : "not uploaded"}"
  // }
  // If Yes, consider the status as "uploaded successfully".
  // JSON format should look like this and store in a variable called "data" and ask, please check all details and Do you want to apply for leave? note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes', 'No']
  // "data":    {
  //   "emp_no": "${empNo}",
  //   "folder_id": "vacation",
  //   "remark": "I want vacation",
  //   "file": "${uploadFile ? "uploaded file details" : "not uploaded"}",
  //   "status": "submitted"
  // }
  // ${
  //   uploadInProgress
  //     ? "After successfully uploading the document, you can continue with other actions."
  //     : ""
  // }
  // Feel free to respond conversationally and guide the employee through the process.
  // At the end, ask the employee "Do you want to end this conversation or start a new conversation?" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;

  const leaveContext = `
        You are the Employee Leave Application ChatBot, here to assist with leave applications.
    Your goal is to collect leave application details and generate a JSON representation of the application exactly what data employee had entered.\
    when user in between ask any question not relate to leave Application ,ask employee do you want to close the leave application context
    First display this information 
    Leaves Details: ${JSON.stringify(leaveList)} then
    You can ask for the following information: 
    - Type of leave (e.g., casual leave, sick leave, unpaid leave, earned leave): note: also provide Dropdown array(note: Dropdown D should be capital)  like this, ex: Dropdown: ['Casual Leave', ' Sick Leave', 'Unpaid Leave', 'Earned Leave']
    - Start date of your leave (from_date) in YYYY-MM-DD format:
    - End date of your leave (to_date) in YYYY-MM-DD format: 
    calculate the duration key as the number of days between the start and end date.
    check if the duration key as the number of days is less than days available for the selected leave type. If available then follow Rule 1; otherwise, follow Rule 2.
    Rule 1: Full day or half day leave? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Full Day', 'Half Day']
    - Reason for your leave:
    Ask each question one by one  and store in respective json key. and take emp_no as ${empNo} \
    remember every employee input and add to respective json key given in format below.\
    calculate leave_no key from Type of leave if leave type is casual leave then 1, sick leave is 2, unpaid is 3, earned leave is 4.\
    calculate duration key as number of days between start and end date and multiply by 8. for ex: duration is 2 days then show 16.\
    Json format should look like this and store in variable called "data" and ask please check all details and Do you want to apply the leave? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes', 'No']
    "data":    {
      "emp_no": "12345",
      "leave_no": "vacation",
      "from_date": "2023-09-01",
      "to_date": "2023-09-02",
      "duration": "16",
      "day": "full day",
      "reason": "I need a break to recharge."
    }    
    If Yes consider status as "submitted".\
    Json format should look like this and store in variable called "data" and ask please check all details and Do you want to upload the document? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes', 'No']
    "data":    {
      "emp_no": "12345",
      "leave_no": "vacation",
      "from_date": "2023-09-01",
      "to_date": "2023-09-02",
      "duration": "16",
      "day": "full day",
      "reason": "I need a break to recharge."
      "status": "submitted" 
    }
    After successfully leave applies give employees all updated leaves names with available leaves count.
    Rule 2 : Say you don't have leaves.   
    
       Feel free to respond conversationally and guide the employee through the process.
       At the end ask to employee "Do you want to end this conversation or start new conversation" Provide Buttons array (note: Buttons B should be capital) for exit Buttons: ['New Chat', 'Exit Chat']`;
  // Set the initial context
  const [context, setContext] = useState(initialContext);

  console.log("emp_no", empNo);

  const getCompletionFromMessages = async (ncontext) => {
    try {
      console.log("ncontext", ncontext);
      console.log("context in getcompletion....", context);
      const res = await openai.createChatCompletion({
        model: "gpt-4-0613",
        // model: "gpt-3.5-turbo",
        messages: ncontext,
        temperature: 0,
      });
      console.log(
        "getCompletionFromMessages",
        res.data.choices[0].message.content
      );
      console.log(res.data.choices[0].message.content);
      let assres = res.data.choices[0].message.content;
      SetAssistant(assres);
      if (
        assres.includes("choose the file") ||
        assres.includes("choose a file")
      ) {
        SetShowFileUpload(true);
      }
      if (assres.includes("YYYY-MM-DD")) {
        setShowDatePicker(true);
      }
      if (assres.includes("Button")) {
        let buttonN = extractButtonNames(assres);
        SetbuttonNames(buttonN);
        console.log("buttonN", buttonN);
      }
      if (assres.includes("data")) {
        let leavejson = extractleavejson(assres);
        console.log("leavejson", leavejson);
      }
      if (assres.includes("doc-data")) {
        let leavejson = extractdocjson(assres);
        console.log("DOCleavejson", leavejson);
      }
      if (demo === "Upload") {
        console.log("call upload function");
        handleUpload();
      }
      if (assres.includes("Dropdown:")) {
        let dropdownopt = extractDropdownopt(assres);
        SetDropdownNames(dropdownopt);
        console.log("dropdownopt", dropdownopt);
      }

      SettextBeforeButtons(assres.split("Buttons:")[0]);
      let textBeforeBut = assres.split("Buttons:")[0];
      // co newassres = assres.split("Buttons:")[0];
      console.log("response of user", demo);
      let userMessage;
      let newContext;
      if (response !== "") {
        userMessage = { text: response, user: true };
        newContext = [
          ...ncontext,
          // { role: "user", content: response },
          { role: "assistant", content: assres },
        ];
      } else {
        userMessage = { text: demo, user: true };
        newContext = [
          ...ncontext,
          // { role: "user", content: demo },
          { role: "assistant", content: assres },
        ];
      }
      const aiResponse = { text: textBeforeBut, user: false };
      if (demo.includes("New")) {
        setMessages([userMessage, aiResponse]);
      } else {
        setMessages([...messages, userMessage, aiResponse]);
      }
      // setMessages([...messages, userMessage, aiResponse]);
      setContext(newContext);
      console.log("updated context", newContext);
      setResponse("");
      // SetbuttonNames([]);
    } catch (error) {
      console.error("Error fetching AI response", error);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the component is initially rendered
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    if (messages.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (response.trim() !== "") {
      let prompt = response;
      console.log("prompt", prompt);
      const nContext = [...context, { role: "user", content: prompt }];
      setContext(nContext);
      const userMessage = { text: response, user: true };
      const aiResponse = { text: "loading...", user: false };
      setMessages([...messages, userMessage, aiResponse]);

      setResponse("");
      setLoadingResponse(true); // Show loading window
      try {
        await getCompletionFromMessages(nContext); // Wait for completion
      } catch (error) {
        console.error("Error sending message", error);
      }
      console.log("handleSendMessage", assistant);
    }
  };

  return (
    <div className="chat-container" ref={chatContainerRef}>
      <Col className="chat-col lg-10">
        <div className="message-container">
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                alignSelf: message.user ? "flex-end" : "flex-start",
                backgroundColor: message.user ? "#e1ffc7" : "#f0f0f0",
                borderRadius: "10px",
                padding: "8px 12px",
                marginBottom: "5px",
                maxWidth: "80%",
              }}
            >
              {message.user ? (
                message.text
              ) : (
                <>
                  {message.text.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      <div dangerouslySetInnerHTML={{ __html: line }} />
                      {/* {line}
                      </div> */}
                      {/* <br /> */}
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          ))}
          {showDatePicker && (
            <>
              <DatePicker
                placeholderText="Choose date"
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
              />
            </>
          )}
          {showFileUpload && (
            <div>
              <input type="file" onChange={handleFileSelect} />
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
            </div>
          )}
          {dropdownNames.length > 0 && (
            <div>
              <select onChange={handleDropdownChange}>
                <option value="">Select an option</option>
                {dropdownNames.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* Render buttons outside of the message loop */}
          {textBeforeButtons !== "" && (
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                {buttonNames.map((buttonName, index) => (
                  <button
                    className="send-button"
                    key={index}
                    onClick={() => {
                      demo = buttonName;
                      setButtonClicked(buttonName);
                      setResponse(buttonName);
                      let nContext;
                      if (buttonName === "List of Holidays") {
                        nContext = [
                          { role: "system", content: holidayContext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName.includes("leave")) {
                        // leaves_listapi()
                        console.log("leavedata in contect");
                        console.log("leave appended.......");
                        nContext = [
                          { role: "system", content: leaveContext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName.includes("Documents")) {
                        // leaves_listapi()
                        console.log("docdata in contect");
                        console.log("doc appended.......");
                        nContext = [
                          { role: "system", content: docContext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName.includes("New")) {
                        console.log("New Chat appended.......");
                        nContext = [
                          { role: "system", content: newChat },
                          { role: "user", content: buttonName },
                        ];
                      } else {
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                      }

                      setContext(nContext);
                      const userMessage = {
                        text: buttonName,
                        user: true,
                      };
                      const aiResponse = {
                        text: "loading...",
                        user: false,
                      };
                      if (buttonName.includes("New")) {
                        setMessages([userMessage, aiResponse]);
                      } else {
                        setMessages([...messages, userMessage, aiResponse]);
                      }

                      getCompletionFromMessages(nContext);
                      SetbuttonNames([]);
                    }}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div ref={lastMessageRef}></div>
        </div>

        <div className="input-container" style={{ padding: "20px" }}>
          <input
            type="text"
            placeholder="Type your message..."
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            className="input-text"
          />
          <button onClick={handleSendMessage} className="send-button">
            Send
          </button>
        </div>
      </Col>
    </div>
  );
};

export default AIChatBot;
