import * as React from "react";
import AuthContext from "./AuthContext";
import axiosURL from "../components/Alert/axios";

export function GetCurrentUser() {
  const userString = sessionStorage.getItem("currentUser");
  if (userString) {
    try {
      return JSON.parse(userString);
    } catch {
      return null;
    }
  }
  return null;
}

export function ResetUser() {
  sessionStorage.removeItem("currentUser");
}
// console.log("GetCurrentUser():-", GetCurrentUser());
export default function AuthProvider({ children }) {
  const [user, setUser] = React.useState(GetCurrentUser());
  const [toggleType, setToggleType] = React.useState("Employee");
  const [chatMsg, setChatMsg] = React.useState([]);
  const [allEmployees, setAllEmployees] = React.useState([]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    // const expiryInMilliSecond = (user.login.expiresInSec - 60) * 1000;
    // const timeout = setTimeout(() => {
    //   resetUser();
    // }, expiryInMilliSecond);

    // return () => clearTimeout(timeout);
  }, [user]);
  React.useEffect(() => {
    const locals = localStorage.getItem("chatMsg");
    console.log("locals:-", locals);
    // setChatMsg(localStorage.getItem("chatMsg"));
    // console.log("localStorage:-", localStorage);
  }, []);

  const getAllEmployeesFunction = async () => {
    try {
      const empDataRes = await axiosURL.get(`/api/employee_details/`);
      setAllEmployees(empDataRes.data.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await getAllEmployeesFunction();
    };

    fetchData();
  }, []);

  const setChatMsgfun = (data) => {
    setChatMsg([...chatMsg, data]);
  };
  const setSignedInUser = (user) => {
    console.log("user:-", user);
    setToggleType(user.employeeType);
    setUser(user);
    // console.log("setSignedInUser:-");
    sessionStorage.setItem("currentUser", JSON.stringify(user));
  };

  const setToggleTypefunction = (usertype) => {
    setToggleType(usertype);
  };

  const resetUser = () => {
    setUser(null);
    sessionStorage.removeItem("currentUser");
  };

  const value = {
    user,
    toggleType,
    setSignedInUser,
    setToggleTypefunction,
    resetUser,
    chatMsg,
    setChatMsgfun,
    allEmployees,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
