import React from "react";

const AuthUser = {
  user: null,
  toggleType: null,
  chatMessages: null,
  setSignedInUser: () => {},
  resetUser: () => {},
};

const AuthContext = React.createContext(AuthUser);
export default AuthContext;
