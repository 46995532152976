import React from 'react';
import './table.css';

const Table = ({ data, columns, selectedColumns, onColumnSelect }) => {
  return (
    <table>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedColumns.includes(column)}
                  onChange={() => onColumnSelect(column)}
                  //   disabled={
                  //     !selectedColumns.includes(column) &&
                  //     selectedColumns.filter((col) => col !== column).length >= 2
                  //   }
                />
                {column}
              </label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* {data.map((row, index) => (
          <tr key={index}>
            {columns.map((column) => (
              <td key={column}>
                {selectedColumns.includes(column) ? row[column] : ''}
              </td>
            ))}
          </tr>
        ))} */}
        {data.map((row, index) => (
          <tr key={index}>
            {columns.map((column) => (
              <td key={column}>{row[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;